.blockHeader {
  display: none;
}
.blockHeaderWhite {
  display: none;
}

@media screen and (max-width: 414px) {
  .blockHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 40px 0 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);

    &__num {
      font-size: 14px;
      font-weight: 400;
      margin-right: 10%;
    }

    &__name {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 24px;
      line-height: 20px;
      text-align: left;
    }
  }

  .blockHeaderWhite {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 40px 0 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);

    &__num {
      color: white;
      font-size: 14px;
      font-weight: 400;
      margin-right: 6%;
    }

    &__name {
      color: white;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 24px;
      line-height: 17px;
    }
  }
}
