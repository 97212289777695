.wrapperCorporate {
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.6) 60%);
  padding: 0 5%;
  height: auto;
  margin-bottom: 0;
}

.introduceCorporate {
  background-image: url("../../../public/img/pictures/BREAKIN-25.jpg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;

  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    padding-bottom: 200px;

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      margin: 0 auto;
      gap: 2rem 0;
      width: 40%;

      &__text {
        

        &--headWhite {
          color: #fff;
          font-size: 60px;
          font-weight: 900;
          text-transform: uppercase;
          // width: 50%;
          margin-top: auto;
          
        }

        &--headPink {
          color: #e53e63;
          font-size: 60px;
          font-weight: 900;
          text-transform: uppercase;
          // width: 50%;
          margin-bottom: 1rem;
        }

        &--paragraph {
          color: #fff;
          font-size: 24px;
          // width: 46%;
          width: 415px;
          margin-bottom: 1rem;
        }
        &--gray {
          color: #737373;
          font-size: 14px;
        
        }
      }
    }
  }
}
.formbox {
  display: flex;
  flex-direction: column;
}
.statusFormFailed,
.statusFormSuccess {
  display: flex;
  margin: 20px auto;
}

.statusFormSuccess {
  color: #fff;
  color: rgb(14, 185, 29);
}

.statusFormFailed {
  color: red;
}

.formCorporate {
  margin-bottom: 80px;

  &__form {
    display: flex;
    flex-direction: column;
    width: 415px;
    margin: 0 auto;
    gap: 10px 0;

    &__fields {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;

      &--errorField {
        position: absolute;
        color: white;
        top: 70px;
        right: 0;
        font-size: 10px;
      }

      &--mini {
        width: 48%;
        position: relative;
      }

      label {
        position: absolute;
        color: white;
        left: 0;
        top: -8px;
        font-size: 14px;
        font-family: "DIN Next LT Pro", sans-serif,;
      }

      input {
        width: 100%;
        height: 55px;
        background-color: rgba(0, 0, 0, 0.4);
        border: 1px solid rgba(255, 255, 255, 0.4);
        color: white;
        padding: 0 10px 0;
        border-radius: 2px;
        margin: 12px 0;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        -webkit-text-fill-color: white;
        transition: background-color 5000s ease-in-out 0s;
      }

      input:active,
      input:focus {
        outline: 1px solid #e53e63;
      }
    }
  }
  .corpRequest {
    width: 100%;
    cursor: pointer;

    &:active {
      background-color: #c53555;
    }
  }
}

select {
  width: 100%;
  height: 55px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.4);
  margin-top: 10px;
  color: white;
  text-align-last: center;
  cursor: pointer;

  &:active, &:focus {
    outline: 1px solid #e53e63;
  
  }
}

select option {
  background-color: white;
  color: black;
  text-align: center;
  font-size: 14px;
  font-weight: 700;

}
select option:hover, select option:focus, select option:active {
  background-color: #e53e63;
  filter: invert(1);
}

@media screen and (max-width: 960px) {
  .wrapperCorporate {
    margin-bottom: 20px;
  }

  .introduceCorporate {
    &__section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 200px;
      padding-bottom: 100px;

      &__body {
        width: 60%;
        margin-bottom: 40px;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .introduceCorporate {
    &__section {
      justify-content: flex-start;

      &__body {
        width: 100%;
        text-align: left;
        &__text {
          &--headWhite {
            font-size: 40px;
          }

          &--headPink {
            font-size: 40px;
          }

          &--paragraph {
            font-size: 20px;
            width: 80%;
          }
        }
      }
    }
  }
  .formCorporate {
    margin-bottom: 80px;

    &__form {
      display: flex;
      flex-direction: column;
      width: 90vw;
      margin: 0 auto;
      padding-bottom: 40px;
    }
  }
}
