.contacts {
  background-color: #343137;

  color: white;

  &--title {
    padding-top: 16px;
  }

  &--Info,
  &--adress {
    font-size: 14px;
    border-bottom: 1px solid #3f4458;
    padding: 8px 0;
    line-height: 30px;

    span {
      font-size: 15px;
      margin: 1rem 0;
    }

    a {
      text-decoration: none;
      color: white;
      margin: 1rem 0;
    }

    .icon {
      margin-right: 10px;

      img {
        width: 50px;
      }
    }
  }

  &--howToReach {
    text-align: center;
    font-size: 14px;
    border-bottom: 1px solid #3f4458;
    line-height: 30px;
    padding: 8px 0;
    margin: 0 auto;

    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      max-width: 460px;
      margin: 0 auto;
    }

    img {
      margin-right: 8px;
    }
  }
}

.mainContacts {
  display: none;
}

.contactsMob {
  display: none;
}

@media screen and (max-width: 414px) {
  .contacts {
    display: none;
  }
  .contactsMob {
    display: block;
    background-color: #191919;
    padding: 2rem 5%;
    color: white;

    &--title {
      font-weight: 900;
      font-size: 32px;
      line-height: 38px;
      text-transform: uppercase;
      text-align: left;
    }

    &--titleText {
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      margin: 1rem 0;
    }

    &__adress {
      font-size: 14px;
      padding: 1rem 0;
      line-height: 30px;

      &--headOfContacts {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 2rem;

        .mainContacts {
          display: block;
          font-weight: 900;
          font-size: 32px;
          line-height: 38px;
          text-transform: uppercase;
          width: 30%;
          text-align: left;
        }

        .clockOpen {
          background-image: url(../../../public/img/logo/clock.png);
          height: 16px;
          width: 16px;
          position: absolute;
          top: 0.4rem;
          left: 0.2rem;
          background-size: contain;
        }

        .openGroup {
          background-color: white;
          color: black;
          width: 56%;
          border-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 1rem;
          padding-right: 0.2rem;
          position: relative;
          font-weight: 400;

          span {
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            color: #737373;
            margin: 0 0;
            width: 3rem;
            display: block;
          }
        }

        .clockTime {
          display: block;
        }
      }
      &__info {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 1rem;
        line-height: 22px;
        font-weight: 400;
        font-size: 18px;

        &--first,
        &--second,
        &--third {
          display: flex;
          justify-content: flex-start;
          gap: 0.7rem;
          text-align: left;

          p {
            font-size: 14px;
            width: 60px;
          }
          a {
            text-decoration: underline;
            color: white;
          }
        }
      }
    }

    &__Info {
      font-style: normal;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid rgba(255, 255, 255, 0.4);
      padding: 1rem;
      text-align: left;
      margin-bottom: 1rem;

      img {
        height: 64px;
      }

      &--InfoTitle {
        text-transform: uppercase;
        font-size: 18px;
        line-height: 22px;
        opacity: 0.4;
      }

      &--InfoText {
        margin-top: 1rem;
        font-size: 14px;
        line-height: 17px;
        width: 56vw;
      }
    }
  }
}
