.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.Modal {
  position: relative;
  background: #000000;
  width: 80%;
  height: auto;
  max-width: 900px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  z-index: 999;

}

.modal-video {
  width: 100%;
}

.modal2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, rgb(56, 29, 110), rgb(226, 135, 130));
}

.modal-content2 {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.close-button2 {
  position: absolute;
  top: 20px;
  right: 20px;
}

.voucherField {
  width: 44vw;
  margin: 0 auto;
  color: #fff;
  padding-top: 60px;
  position: relative;

  & h1 {
    font-size: 40px;
    color: white;
    line-height: normal;
    font-weight: 400;
    text-align: center;
    padding-top: 2rem;
  }

  & p {
    font-size: 14px;
    color: #ccc;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .formsGroup {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 1rem;
    align-items: center;
   

    .add_group {
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
      width: 50%;
     
    }
    .add_group2 {
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
      width: 100%;
    }

    &__forms2 {
      width: 100%;
      gap: 1rem;
      position: relative;

      label {
        font-size: 12px;
      }

      .validPromoCode {
        border-bottom: 1px solid rgb(32, 192, 32);
      }

      .invalidPromoCode {
        border-bottom: 1px solid red;
      }
    }

    &__forms3 {
      width: 50%;
      gap: 1rem;
      position: relative;

      & label {
        font-size: 12px;
      }
    }

    &__forms {
      width: 50%;
      gap: 1rem;
      position: relative;

      & label {
        font-size: 12px;
      }

      &--formField {
        background-color: transparent;
        width: 100%;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        margin-bottom: 4rem;
        height: 2rem;
        font-size: 18px;
        outline: none;
        caret-color: white;
        color: #fff;
        position: relative;
        margin-top: 0;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
          -webkit-text-fill-color: white;
          transition: background-color 5000s ease-in-out 0s;
        }

        &:hover,
        &:focus,
        &:active {
          border: none;
          border-bottom: 1px solid rgba(255, 255, 255);
        }

        option {
          color: #000;
          font-size: 16px;
          font-weight: bold;
        }
      }

      &--errorField {
        position: absolute;
        left: 0;
        top: 4rem;
        opacity: 1;
        font-size: 12px;
        color: #c00;
      }
    }
  }
}

.afterForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 180px;

  label {
    font-size: 14px;
    margin-top: 6px;

    a {
      color: #ccc;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: white;
        text-decoration: underline;
        text-underline-offset: 6px;
      }
    }
  }

  .pricesForBuy {
    margin-top: 25px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 14px;
    padding: 20px 60px;
    display: flex;
    align-items: center;
    font-family: "DINNextLTPro-Regular", sans-serif;
    position: relative;

    &--number {
      text-decoration-line: line-through;
      text-decoration-color: red;
      text-decoration-thickness: 3px;
    }

    p {
      display: inline-block;
      color: white;
      margin: 0;
    }

    span {
      display: inline-flex;
      font-size: 25px;
      padding-left: 13px;
    }

    // mark {
    //   color: red;
    //   position: absolute;
    //   background-color: transparent;
    //   font-size: 60px;
    //   font-weight: 100;
    // }
  }
}
.formsGroup__forms-relative--errorField {
  color: #f00;
  margin-top: 16px;
  margin-bottom: 7px;
  font-size: 13px;
}
@media all and (max-width: 768px) {
  .voucherField {
    width: 94%;
    padding-top: 30px;

    & h1 {
      font-size: 40px;
      color: white;
      line-height: normal;
      font-weight: 400;
      text-align: center;
      padding-top: 3rem;
    }

    & p {
      font-size: 14px;
      color: #ccc;
      text-align: center;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }

    .formsGroup {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;
      align-items: center;

      .add_group {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;
      }

      .add_group2 {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;
      }

      &__forms2 {
        width: 100%;
        gap: 1rem;
        position: relative;

        label {
          font-size: 12px;
        }

        .validPromoCode {
          border-bottom: 1px solid rgb(32, 192, 32);
        }

        .invalidPromoCode {
          border-bottom: 1px solid red;
        }
      }

      &__forms3 {
        width: 100%;
        gap: 1rem;
        position: relative;

        & label {
          font-size: 12px;
        }
      }

      &__forms {
        width: 100%;
        gap: 1rem;
        position: relative;

        & label {
          font-size: 12px;
        }

        &--formField {
          background-color: transparent;
          width: 100%;
          border: none;
          border-bottom: 1px solid rgba(255, 255, 255, 0.4);
          margin-bottom: 4rem;
          height: 2rem;
          font-size: 18px;
          outline: none;
          caret-color: white;
          color: #fff;
          position: relative;

          &:hover,
          &:focus,
          &:active {
            border: none;
            border-bottom: 1px solid rgba(255, 255, 255);
          }

          option {
            color: #000;
            font-size: 16px;
            font-weight: bold;
          }
        }

        &--errorField {
          position: absolute;
          left: 0;
          top: 4rem;
          opacity: 1;
          font-size: 12px;
          color: #c00;
        }
      }
    }
  }
  .afterForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 180px;

    label {
      font-size: 14px;
      margin-top: 6px;
      text-align: left;

      a {
        color: #ccc;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: white;
          text-decoration: underline;
          text-underline-offset: 6px;
        }
      }
    }
  }
}
