.footerBlock {
  background-color: #29252f;
  padding-bottom: 3rem;
  padding-top: 3rem;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-row: 1;

  > img {
    position: absolute;
    left: 13%;
    top: 30%;
  }
}

.footer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2rem;
  font-size: 14px;
  grid-column: 2;

  &--link {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.3);
  }

  &--link:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 6px;
  }
}

.social {
  display: flex;
  justify-content: flex-start;
  gap: 8%;
  grid-column: 3;

  &--owl,
  &--fb,
  &--twi,
  &--inst {
    position: relative;
    height: 24px;
    width: 24px;
    object-fit: scale-down;

    img {
      height: 16px;
      object-fit: scale-down;
    }
  }
  &--owl::after,
  &--fb::after,
  &--twi::after,
  &--inst::after {
    content: "";
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    left: -9px;
    top: -12px;
  }
}

.footerBlockMobile {
  display: none;
}
@media screen and (max-width: 820px) {
  .footerBlock {
    display: none;
  }

  .footerBlockMobile {
    display: block;
    background-color: #191919;
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding: 4% 5%;
  }

  .footerUp {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}
  

  .footerNobile {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .social {
    display: flex;
    justify-content: flex-end;
    gap: 12%;
    grid-column: 3;
    width: 70%;

    &--owl,
    &--fb,
    &--twi,
    &--inst {
      position: relative;
      height: 24px;
      width: 24px;
      object-fit: scale-down;

      img {
        height: 16px;
        object-fit: scale-down;
      }
    }
    &--owl::after,
    &--fb::after,
    &--twi::after,
    &--inst::after {
      content: "";
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      height: 40px;
      width: 40px;
      position: absolute;
      left: -9px;
      top: -12px;
    }
  }
}
