.mobOutdoor {
  margin-top: 30px;
}

.introduceNewMobile {
  background-image: url("../../public/img/pictures/Intro2.jpg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 90vh;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: linear-gradient(to bottom, transparent, rgba(0,0,0,.6) 60%);
    height: 100%;
    padding: 0 5% 120px;
    width: 100%;
    margin: 0 auto;
    gap: 2rem 0;
    margin-bottom: 4rem;
  }

  &__text {
    text-align: left;

    &--headWhite {
      color: #fff;
      font-size: 60px;
      font-weight: 900;
      text-transform: uppercase;
      width: 50%;
      margin-bottom: 1rem;
    }

    &--headPink {
      color: #e53e63;
      font-size: 40px;
      font-weight: 900;
      text-transform: uppercase;
      width: 100%;
      max-width: 360px;
      margin-bottom: 1rem;
    }

    &--paragraph {
      color: #fff;
      font-size: 24px;
      width: 46%;
      margin-bottom: 1rem;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // justify-content: center;
    align-items: center;
    gap: 1rem 2rem;
    width: 78%;

    .newButtonBook {
      width: 200px;
      padding: 1rem 0;
      text-transform: uppercase;
      font-size: 14px;
      height: 46px;
      font-family: "DINNextLTPro-Regular", sans-serif;
    }

    div {
      width: 200px;
      position: relative;
    }
  }
}
@media screen and (max-width: 1000px) {
  .introduceNewMobile {
    &__text {
      &--paragraph {
        width: 100%;
      }
    }
    &--nav {
      padding: 0 5%;
    }
    &__buttons {
      width: 100%;
  }
}
}
@media screen and (max-width: 414px) {
  .textForButtonCorporate {
    left: 40px;
    top: 26px;
  }

  .buttonCorporate {
    font-size: 10px;
    justify-content: center;
    padding: 1rem 1rem;
    width: 100%;
    height: 46px;
  }

  .introduceNewMobile {
    display: block;
    background-image: url(../../public/img/pictures/Intro2.jpg);
    height: 700px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;

    &--nav {
      padding: 0 5%;
    }

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background: linear-gradient(to bottom, transparent, black 60%);
      height: 636px;
      padding: 0 5% 120px;
      margin: 0 auto;
      gap: 1rem 0;
      margin-bottom: 4rem;
    }

    &__text {
      text-align: left;

      &--headWhite {
        color: #fff;
        font-size: 32px;
        font-weight: 900;
        text-transform: uppercase;
        width: 50%;
        margin-bottom: 0;
      }

      &--headPink {
        color: #e53e63;
        font-size: 20px;
        font-weight: 900;
        text-transform: uppercase;
        width: 50%;
        margin-bottom: 0;
      }

      &--paragraph {
        color: #fff;
        font-size: 18px;

        width: 80%;
      }
    }

    &__buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 1rem 0;
      width: 100%;

      .newButtonBook {
        width: 164px;
        padding: 18px 0;
        text-transform: uppercase;
        font-size: 10px;
        height: 46px;
      }

      div {
        width: 168px;
        position: relative;
      }
    }
  }
}
