@import url(../../index.css);
.features {
  display: none;
}

@media screen and (max-width: 768px) {
  .features {
    display: block;
    margin-bottom: 8%;
    margin-top: 4%;
    padding: 0 5%;

    &__property {
      margin-top: 2rem;
      

      &__list {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0;

        &__li {
          width: auto;
          border: none;
          border-radius: 10px;
          position: relative;
          padding: 12px 0;
          text-align: left;
          list-style-type: none;
          padding-left: 76px;

          &--imageRound {
            background-image: url("../../../public/img/logo/Round.png");
            height: 58px;
            width: 58px;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            top: 38px;
            left: 0;
          }

  
          &--imageEye {
            background-image: url("../../../public/img/logo/eye.svg");
            height: 32px;
            width: 32px;
            position: absolute;

          }
          &--imageClock {
            background-image: url("../../../public/img/logo/clock.png");
            height: 32px;
            width: 32px;
            position: absolute;

          }
          &--imagePuzzle {
            background-image: url("../../../public/img/logo/blackPuzzle.svg");
            height: 32px;
            width: 32px;
            position: absolute;

          }
          &--imageHeart {
            background-image: url("../../../public/img/logo/heart.svg");
            height: 32px;
            width: 32px;
            position: absolute;

          }

          &--imageHeart,
          &--imageEye,
          &--imagePuzzle,
          &--imageClock {
            top: 51px;
            left: 13px;
        
          }
          &--info_number1,
          &--info_number2,
          &--info_number3,
          &--info_number4 {
            font-weight: 900;
            font-size: 30px;
            line-height: 30px;
            margin-top: 12px;
          }
          &--info_number1 {
            color: #e53e63;
          }

          &--info_number2 {
            color: #765da9;
          }

          &--info_number3 {
            color: #000000;
          }

          &--info_number4 {
            color: #999999;
          }

          &--number {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #999;
            display: inline;
          }
        }
      }
    }
  }
}
