@import url(../../index.css);

.introduce {
  overflow: hidden;
  min-height: 900px;
  padding: 0 90px;
  position: relative;

  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  }

  .introduceBlock {
    padding-top: 10rem;
    display: flex;
    justify-content: center;
    gap: 10%;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;

    &__group {
      display: flex;
      flex-direction: column;
      position: relative;
      flex-basis: 1000px;

      &--img {
        height: 140px;
        width: 740px;
        position: absolute;
      }

      &--text {
        font-size: 60px;
        line-height: 60px;
        color: white;
        text-align: left;
        font-weight: 700;
        // font-family: "DINNextLTPro-Regular", sans-serif;
        margin-top: 150px;
      }

      &--additionalImg {
        height: 234px;
        width: 210px;
      }
    }

    &--buttons {
      width: 80%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
      margin-top: 2rem;
    }
  }
}

.intoduceMobile {
  overflow: hidden;
  width: 100%;
  height: 300px;
  display: block;

  &--Block {
    display: none;
    padding: 20px;
  }

  &--Block.active {
    display: block;
  }
}

.navigation {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.navButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
}

.navButton.active {
  color: red;
}



@media screen and (max-width: 800px) {
  .breakingMobileImg {
    position: absolute;
    z-index: -3;
    top: 0;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    left: 0;
  }
  .introduce {
    overflow: hidden;
    background: #000 url(../../../public/img/logo/bg_actions_july.jpg) no-repeat left top;
    background-size: cover;

    padding: 0 5%;
    position: relative;
    text-transform: uppercase;

    .background-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      object-fit: cover;
    }

    .introduceBlock {
      padding-top: 60px;
      display: flex;
      justify-content: center;
      gap: 10%;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;

      &__group {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top:0;

        &--img {
          height: 100px;
          width: 500px;
          position: absolute;
          top: 0;
        }

        &--text {
          font-size: 50px;
          line-height: 50px;
          color: white;
          text-align: left;
          font-weight: 700;

          span {
            color: #e53e63;
          }
        }

        &--additionalImg {
          height: 234px;
          width: 210px;
        }
      }

      &--buttons {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 0;
        margin-top: 0;
      }
    }
  }
}



@media screen and (max-width: 428px) {
  .breakingMobileImg {
    object-position: left;
  }

  .introduce {
    min-height: 800px;
    overflow: hidden;
    height: auto;
    padding: 0 16px;
    position: relative;

    .background-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      object-fit: cover;
    }

    .introduceBlock {
      justify-content: flex-start;
      padding-bottom: 4rem;
      margin-top: 80px;

      &__group {
        display: flex;
        flex-direction: column;
        position: relative;
        // margin-top: 10rem;

        &--img {
          display: none;
        }

        &--text {
          font-size: 38px;
          line-height: 35px;
          color: white;
          text-align: left;
          font-weight: 700;
          width: 80%;
          margin-bottom: 1rem;
          margin-top: 240px;
        }

        &--additionalImg {
          height: 234px;
          width: 210px;
        }
      }

      &--buttons {
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        gap: 4px;
      }
    }
  }
}
