.otherCompanyContainer {
  padding: 15px;

  &__ImageGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 600px;
    margin: 0 auto;


    &__item {
      flex-basis: 33.33%;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      &--img {
        max-width: 130px;
      }
    }
  }

  &__header  {
    padding: 20px 0 30px;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 30px;
  }
}

@media screen and (max-width: 960px) {
  .otherCompanyContainer {
    padding: 15px;

    &__ImageGroup {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &__item {
        flex-basis: 33.33%;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        &--img {
          max-width: 100px;
        }
      }
    }

    &__header  {
      padding: 20px 0 30px;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 414px) {
  .otherCompanyContainer {
    &__header {
      display: none;
    }
  }
}
