* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.outdoor {
  background-repeat: no-repeat;
  background-size: unset;
  color: white;
  height: auto;
  width: 100%;
  min-height: 600px;
  padding-left: 50vw;
  background-image: url(../../../public/img/pictures/opermind.jpg);
  background-position: center 0;
  font-size: 16px;

  .contentOfRooms {
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    gap: 1rem;
    height: auto;
    padding-top: 5%;
    padding-right: 20px;
  }
  .paragraph{
    max-width: 650px;

    h2{
      img{
        width: 80px;
        border: 1px solid rgba(255,255,255,0.2);
        border-radius: 50%;
        padding: 15px;
      }

    }

    p{
        line-height: 1.41em;
        color: #fff;
        display: block;
        text-align: left;
        padding-bottom: 5px;
        padding-top: 6px;
        opacity: 0.8;
    }
  }
}

.wizarding,
.cave,
.sherlock,
.dutchman,
.alpha,
.heist,
.butcher {

  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  height: auto;
  font-size: 0.8vw;
  width: 100%;
  min-height: 825px;
}

.wizarding:not(:first-child),
.cave:not(:first-child),
.sherlock:not(:first-child),
.dutchman:not(:first-child),
.alpha:not(:first-child),
.heist:not(:first-child) {
  margin-top: 0;
}

.wizarding {
  padding-left: 50vw;
  background-image: url(../../../public/img/pictures/wizzard.png);
  background-position: center 0;
}

.cave,
.butcher,
.dutchman {
  padding-left: 15%;
  margin-top: -275px;


  .contentOfRooms {
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    height: auto;
    padding-top: 260px;
    gap: 2rem;
  }
}

.cave{
  .contentOfRooms {
    padding-top: 200px;
    padding-bottom: 150px;
  }
}


  .wizarding,
  .cave,
  .sherlock,
  .dutchman,
  .alpha,
  .heist,
  .butcher {
    font-size: 16px;
  }



.sherlock,
.alpha,
.heist {
  padding-left: 15%;
  margin-top: -270px;


  .contentOfRooms {
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    height: auto;
    padding-top: 160px;
    gap: 2rem;
  }
}

.sherlock,
.alpha {
  padding-left: 50vw;
}


.wizarding {
  .contentOfRooms {
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    gap: 1rem;
    height: auto;
    padding-top: 5%;
  }
}

.wizarding .paragraph,
.heist .paragraph,
.alpha .paragraph,
.sherlock .paragraph {
  max-width: 650px;


  p {
    line-height: 1.41em;
    color: #fff;
    display: block;
    text-align: left;
    padding-bottom: 7px;
    padding-top: 13px;
    opacity: .8;
  }
}

.cave .paragraph,
.dutchman .paragraph,
.butcher .paragraph {
  max-width: 650px;


  p {
    line-height: 1.41em;
    color: #fff;
    display: block;
    text-align: left;
    padding-bottom: 5px;
    padding-top: 6px;
    opacity: .8;
  }
}

.butcher {
  min-height: 900px;
  background-position: center 0;
  background-image: url(../../../public/img/pictures/bubgnew.png);
}

.dutchman {
  background-position: right 0;
  background-image: url(../../../public/img/pictures/pirates.png);
}

.cave {
  background-position: right 0;
  background-image: url(../../../public/img/pictures/blackwing.png);
}

.sherlock {
  margin-top: -300px;
  min-height: 940px;
  background-position: center 0;
  background-image: url(../../../public/img/pictures/sherlock2.png);
}

.alpha {
  margin-top: -290px;
  min-height: 870px;
  background-image: url(../../../public/img/pictures/warbg.png);
}


.heist {
  padding-left: 50vw;
  background-image: url(../../../public/img/pictures/hebg.png);
  background-position: center 0;
  margin-bottom: 0;
}

.wizardImg {
  height: 72px;
  width: 72px;
  object-fit: contain;
  z-index: 2;

  .wizardImg .contentOfRooms {
    padding-top: 14%;
  }
}

.slider {
  /*width: 60%;*/
  font-size: 28px;
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: flex-start;
}

.slider-text {
  display: block;
  width: 80%;
}

.roomButtons .bookingRoom,
.roomButtons button {
  margin-right: 0.8rem;
}

.difficulty {
  background-color: #525769;
  border-radius: 10px;
  padding: 17px 32px;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 13px;

  &--images {
    border-right: 1px solid rgba(255, 255, 255, 0.432);
    height: 20px;
    padding-right: 1rem;

    img {
      margin: 0 0.2rem;
    }
  }
}

.roomMob {
  display: none;
}
.onMobileHeader {
  display: none;
}

@media screen and (max-width: 1500px) {
  .cave .paragraph,
  .dutchman .paragraph,
  .butcher .paragraph {
    width: 70%;
  }

  .wizarding .paragraph,
  .heist .paragraph,
  .alpha .paragraph,
  .sherlock .paragraph {
    width: 100%;
  }

  .wizarding,
  .sherlock,
  .alpha,
  .heist {
    padding-left: 40vw;
    padding-right: 2vw;
  }

  .sherlock .contentOfRooms,
  .alpha .contentOfRooms,
  .heist .contentOfRooms {
    padding-top: 180px;
  }

  .cave .contentOfRooms,
  .dutchman .contentOfRooms,
  .butcher .contentOfRooms {
    padding-top: 260px;
  }

  .slider img {
    height: 8vh;
  }
}
@media screen and (min-width: 2000px) and (max-width: 2600px) {
  .cave .paragraph,
  .dutchman .paragraph,
  .butcher .paragraph {
    width: 70%;
  }



  .wizarding .paragraph,
  .heist .paragraph,
  .alpha .paragraph,
  .sherlock .paragraph {
    width: 100%;
  }

  .wizarding,
  .sherlock,
  .alpha,
  .heist {
    padding-left: 40vw;
    padding-right: 2vw;
  }

  .cave,
  .dutchman,
  .butcher {
    margin-top: -360px;
  }

  .sherlock,
  .alpha,
  .heist {
    margin-top: -360px;
  }

  .sherlock .contentOfRooms,
  .alpha .contentOfRooms,
  .heist .contentOfRooms {
    padding-top: 200px;
  }

  .cave .contentOfRooms,
  .dutchman .contentOfRooms,
  .butcher .contentOfRooms {
    padding-top: 320px;
  }

  .slider img {
    height: 8vh;
  }
}
@media screen and (min-width: 2600px) and (max-width: 3200px) {
  .cave .paragraph,
  .dutchman .paragraph,
  .butcher .paragraph {
    width: 70%;
  }

  .wizarding .paragraph,
  .heist .paragraph,
  .alpha .paragraph,
  .sherlock .paragraph {
    width: 100%;
  }

  .wizarding,
  .sherlock,
  .alpha,
  .heist {
    padding-left: 40vw;
    padding-right: 2vw;
  }

  .cave,
  .dutchman,
  .butcher {
    margin-top: -440px;
  }

  .sherlock,
  .alpha,
  .heist {
    margin-top: -440px;
  }

  .sherlock .contentOfRooms,
  .alpha .contentOfRooms,
  .heist .contentOfRooms {
    padding-top: 360px;
  }

  .cave .contentOfRooms,
  .dutchman .contentOfRooms,
  .butcher .contentOfRooms {
    padding-top: 420px;
  }

  .slider img {
    height: 8vh;
  }
}

@media screen and (max-width: 960px) {
  .rommsBox {
    display: none;
  }

  .roomMap {
    margin: 0 5%;
  }

.roomMob {
    margin: 0 auto;
    height: auto;
    display: block;
    margin-bottom: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.4);
  }

  .wizardMob {
    padding: 0 auto;
    margin: 0 auto;
  }

  .wizardMobImg,
  .caveImg,
  .sherlockImg,
  .dutchmanImg,
  .heistImg,
  .butcherImg,
  .alphaImg {
    object-fit: cover;
    width: 100%;
  }

  .pinkPuzzle {
    background-image: url(../../../public/img/logo/pinkPuzzle.png);
    width: 14px;
    height: 14px;
    margin: 1px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .opacityPuzzle {
    background-image: url(../../../public/img/logo/white_puzzle.png);
    width: 14px;
    height: 14px;
    margin: 1px;
    opacity: 0.4;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .greyPuzzle {
    background-image: url(../../../public/img/logo/puzzleGray.png);
    width: 14px;
    height: 14px;
    margin: 1px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .whitePuzzle {
    background-image: url(../../../public/img/logo/white_puzzle.png);
    width: 14px;
    height: 14px;
    margin: 1px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .descriptionOfRoom {
    position: relative;
  }

  .difficultyMob {
    position: absolute;
    top: -1.6rem;
    left: 24.2%;
    width: 50%;
    border: 1px solid transparent;
    border-radius: 30px;
    /*font-size: 1vw;*/
  }

  .difficulPosition {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: 2.6rem;
    gap: 1rem;
  }

  .difficultyGroup {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .blackDifficult {
    font-size: 3vw;
    color: #000000;
  }

  .whiteDifficult {
    font-size: 3vw;
    color: white;
  }

  .mobile-section {
    display: block;
    background-repeat: no-repeat;
    height: 256px;
    width: 92vw;
    background-size: cover;
  }

  .blackTitle {
    // font-family: "DIN Next LT Pro-Regular",sans-serif;
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    text-transform: uppercase;
    padding-top: 2rem;
    display: block;
    width: 85vw;
    margin: 0 5%;
    text-align: left;
  }
  .whiteTitle {
    font-family: "DINNextLTPro-Regular",sans-serif;
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    text-transform: uppercase;
    padding-top: 2rem;
    display: block;
    width: 85vw;
    color: white;
    margin: 0 5%;
    text-align: left;
  }

  .mobile-text {
    display: block;

    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #737373;
    margin: 0 5%;
  }

  .mobileTextWrapper-purple,
  .mobileTextWrapper-white,
  .mobileTextWrapper-gray,
  .mobileTextWrapper-black {
    position: relative;
  }

  .mobileTextWrapper-purple p,
  .mobileTextWrapper-gray p,
  .mobileTextWrapper-black p {
    color: white;
    margin-top: 1rem;
    text-align: left;
  }

  .mobileTextWrapper-white p {
    margin-top: 1rem;
    text-align: left;
  }

  .white-text {
    color: white;
  }

  .mobileTextWrapper-purple.fade-out::after,
  .mobileTextWrapper-white.fade-out::after,
  .mobileTextWrapper-gray.fade-out::after,
  .mobileTextWrapper-black.fade-out::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10rem;
  }
  .mobileTextWrapper-purple.fade-out::after {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #765da9);
  }
  .mobileTextWrapper-white.fade-out::after {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }
  .mobileTextWrapper-gray.fade-out::after {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #737373);
  }
  .mobileTextWrapper-black.fade-out::after {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgb(0, 0, 0)
    );
  }

  .showButton {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    margin-left: 5%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: flex;
    text-transform: uppercase;

  }

  .buttonsGroup {
    display: flex;
    justify-content: space-between;
    margin: 2% 5%;
    align-items: center;
  }

  .whiteBcgTable,
  .blackBcgTable {
    position: relative;
    height: 60px;
    width: 90px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border-radius: 5px;
   
  }

  .whiteBcgTable {
    border: 1px solid #0000004d;
  }

  .blackBcgTable {
    border: 1px solid white;
    color: white;

  }

  .whiteBcgTable p,
  .blackBcgTable p {
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
    text-transform: uppercase;
    margin-top: 4px;
  }

  .blackBcgTable p {
    color: white;
  }
}
@media screen and (min-width: 961px) and (max-width: 1090px) {
  .cave, .butcher, .dutchman{
    padding-left: 2%;
  }
  .cave .contentOfRooms, .dutchman .contentOfRooms, .butcher .contentOfRooms{
    padding-top: 150px;
  }
  .cave .contentOfRooms{
    padding-bottom: 200px;
  }
  .sherlock{
    margin-top: -350px;
    min-height: 1040px;
  }
  .dutchman{
    background-position: 63% 0;

    .contentOfRooms{
      padding-top: 190px;
    }
  }
  .alpha{
    background-position: 32% 0;
  }
  .butcher{

    .contentOfRooms{
      padding-top: 220px;
    }
  }
  .heist{
    background-position: 50% 0;
  }
}
