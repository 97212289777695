.aboutCompany {
  margin: 0 5% 40px;

  &__header {
    grid-column: 2;
    grid-row: 1;

    &--head {
      font-weight: 700;
      color: #000;
      text-transform: uppercase;
      font-size: 30px;
      line-height: 24px;
      margin-top: 80px;
      margin-bottom: 20px;
    }

    &--text {
      text-transform: uppercase;
      color: #737373;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 40px;
    }
  }

  &__elements {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 10rem);
    justify-content: center;
    gap: 2rem 8rem;
    justify-items: center;

    &__element {
      &--header {
        font-weight: bold;
        font-size: 20px;
        height: 20px;
        margin-bottom: 20px;
      }

      &--upperheader {
        font-weight: bold;
        font-size: 20px;
        height: 20px;
        margin-bottom: 20px;
      }

      &--text {
        color: #737373;
        font-size: 14px;
        line-height: 20px;
      }

      &--img {
        width: 440px;
        height: 522px;
      }
    }

    &__element:nth-of-type(1) {
      grid-column: 2;
      grid-row: 1;
    }

    &__element:nth-of-type(2) {
      grid-column: 1;
      grid-row: 2;
      text-align: right;
    }

    &__element:nth-of-type(3) {
      grid-column: 3;
      grid-row: 2;
      text-align: left;
    }

    &__element--imgage {
      grid-column: 2;
      grid-row: 2;
    }

    &__element:nth-of-type(4) {
      grid-column: 1;
      grid-row: 3;
      text-align: right;
    }

    &__element:nth-of-type(5) {
      grid-column: 3;
      grid-row: 3;
      text-align: left;
    }

    &__element:nth-of-type(6) {
      grid-column: 2;
      grid-row: 4;
      margin-top: 10rem;
    }
  }
}
.aboutMobile {
  display: none;
}
.who-we-are {
  display: none;
}

@media screen and (max-width: 1290px) {
  .who-we-are {
    display: none;
  }
  .aboutMobile {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 300px;
    display: block;
    padding: 0 5%;

    &--image {
      margin: 0 auto;
    }
  }

  .aboutCompany {
    &__elements {
      display: none;
    }
  }
}

// mobile

@media screen and (max-width: 414px) {
  .aboutMobile {
    display: none;
  }

  .aboutCompany {
    display: none;
  }
  .who-we-are {
    display: none;
    padding: 0 5%;
    height: auto;
    margin-bottom: 4%;
    margin-top: 4%;
  }

  .we-head {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    text-transform: uppercase;
    color: #000000;
    position: relative;
    top: 0.5rem;
    border-bottom: 1px solid black;
    padding-bottom: 1rem;
    text-align: left;
  }
  .we-head::before {
    content: "01 /";
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-right: 20%;
  }

  .property-list {
    justify-content: flex-end;
    width: 80vw;
    margin-right: 0;
    margin-left: auto;
    gap: 0;
    flex-wrap: wrap;
    display: flex;
  }

  .property {
    margin-top: 2rem;
  }

  .property-list li {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0 2%;
    width: 88%;
    border: none;
    border-radius: 10px;
    position: relative;
    padding: 0 0;
    text-align: left;
  }
  .propery-info {
    width: 80vw;
  }

  .property-imageRound {
    top: 52px;
    left: -72px;
    background-image: url("../../../public/img/logo/Round.png");
    height: 60px;
    width: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
  }

  .property-imageSquare,
  .property-imageMan,
  .property-imageClock {
    top: 65px;
    left: -57px;
  }

  .property-imageSquare {
    background-image: url("../../../public/img/logo/squarewho.png");
    height: 32px;
    width: 32px;
    position: absolute;
  }

  .property-imageMan {
    background-image: url("../../../public/img/logo/humanwho.png");
    height: 32px;
    width: 32px;
    position: absolute;
  }
  .property-imageClock {
    background-image: url("../../../public/img/logo/clockwho.png");
    height: 32px;
    width: 32px;
    position: absolute;
  }
  .propery-info_number1,
  .propery-info_number2,
  .propery-info_number3 {
    // font-family: "DIN Next LT Pro", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 72px;
    line-height: 60px;
    text-transform: uppercase;
    margin: 2rem 0 1rem;
    display: inline-block;
  }
  .propery-info_number1 {
    color: #e53e63;
  }

  .propery-info_number2 {
    color: #765da9;
  }

  .propery-info_number3 {
    color: #000000;
  }

  .propery-info_desc {
    // font-family: "DIN Next LT Pro", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 31px;

    line-height: 38px;
    text-transform: uppercase;
    color: #737373;
    margin: 0;
  }

  .propery-info_additional {
    // font-family: "DIN Next LT Pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 2rem;
  }
}
