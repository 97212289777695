// .wrapperAboutUsNew {
//   padding: 0 5%;
//   font-family: "DINNextLT Pro", sans-serif;

//   &__upper {
//     display: flex;
//     margin-top: 120px;
//     margin-bottom: 48px;
//     gap: 18%;
//     align-items: flex-start;

//     &--num {
//       color: #737373;
//       font-size: 16px;
//       font-style: normal;
//       font-weight: 400;
//       line-height: normal;
//     }

//     &--header {
//       color: #000;
//       font-size: 32px;
//       font-style: normal;
//       font-weight: 900;
//       line-height: normal;
//       text-transform: uppercase;
//       text-align: left;
//     }

//     &--paragraph {
//       color: #737373;
//       font-size: 16px;
//       font-style: normal;
//       font-weight: 400;
//       line-height: normal;
//       width: 160px;
//       text-align: left;
//     }
//   }
// }

// .aboutUsNewComponent {
//   display: flex;
//   flex-direction: row;
//   gap: 20px;
//   position: relative;

//   &--bord {
//     border-bottom: 1px solid #737373;
//     height: 1px;
//     width: 100%;
//     position: absolute;
//     top: 40px;
//   }

//   &__block {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     position: relative;
//     width: 16%;

//     &--oval {
//       width: 80px;
//       height: 80px;
//       background-color: white;
//       margin-bottom: 60px;
//     }

//     &--logo {
//       width: 32px;
//       height: 32px;
//       position: absolute;
//       left: 24px;
//       top: 24px;
//     }

//     &--header {
//       color: #e53e63;
//       font-size: 22px;
//       font-style: normal;
//       font-weight: 400;
//       line-height: normal;
//       text-align: left;
//       margin-bottom: 24px;
//     }

//     &--text {
//       color: #737373;
//       font-size: 14px;
//       font-style: normal;
//       font-weight: 400;
//       line-height: normal;
//       text-align: left;
//       margin-bottom: 100px;
//     }
//   }
// }

//grids
.wrapperAboutUsNew {
  padding: 0 5%;


  &__upper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    text-align: left;
    margin-top: 80px;
    margin-bottom: 48px;

    &--num {
      color: #737373;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }

    &--header {
      color: #000;
      font-size: 32px;
      font-style: normal;
      font-weight: 900;
      line-height: 32px;
      text-transform: uppercase;
      text-align: left;
      grid-column: span 2;
    }

    &--paragraph {
      color: #737373;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      width: 160px;
      text-align: left;
      grid-column: 4;
      padding-left:10px;
    }
  }

  .aboutUsNewComponent {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    text-align: left;
    gap: 20px;
    position: relative;

    &--bord,
    &--bord2,
    &--bord3 {
      border-bottom: 1px solid #737373;
      height: 1px;
      position: absolute;
    }

    &--bord {
      top: 40px;
      width: 100%;
    }

    &--bord2 {
      display: none;
    }
    &__block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      &--oval {
        width: 80px;
        height: 80px;
        background-color: white;
        margin-bottom: 60px;
      }

      &--logo {
        width: 32px;
        height: 32px;
        position: absolute;
        left: 24px;
        top: 24px;
      }

      &--header {
        color: #e53e63;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        margin-bottom: 24px;
      }

      &--text {
        color: #737373;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        margin-bottom: 100px;
        padding-right: 50px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .wrapperAboutUsNew {
    margin-bottom: 60px;
    &__upper {
      display: grid;
    //   grid-template-rows: (2fr);
      text-align: left;
      margin-top: 80px;
      margin-bottom: 48px;
      gap: 20px;

      &--header {
        display: grid;
        grid-column: span 3;
        
      }

      &--paragraph {
        display: grid;
        grid-column: 2;
        grid-row: 2;
        padding-left:0;
      }
    }
    .aboutUsNewComponent {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      &--bord2 {
        display: block;
        width: 100%;
        top:40px;
        z-index: -2;
        opacity: 0.8;
      }

      &--bord {
        display: none;
      }

    //   &--bord2 {
    //     top: 300px;
    //   }

      &__block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        width: 44%;

        &--oval {
          margin-bottom: 20px;
        }

        &--header {
          margin-bottom: 8px;
        }

        &--text {
          margin-bottom: 20px;
        }
      }
    }
  }
}
