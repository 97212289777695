@font-face {
  font-family: DINNextLTPro-Regular;
  src: 
   url(./fonts/DINNextLTPro-Regular.eot#iefix) format("embedded-opentype"),
    url(./fonts/DINNextLTPro-Regular.woff) format("woff"),
    url(./fonts/DINNextLTPro-Regular.ttf) format("truetype"),
    url(./fonts/DINNextLTPro-Regular.svg#DINNextLTPro-Regular) format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "DIN Next LT Pro";
  src: url("./fonts/DINNextLTPro-Regular.eot?#iefix");
  src: url("./fonts/DINNextLTPro-Regular.eot?#iefix") format("eot"),
  url("./fonts/DINNextLTPro-Regular.woff") format("woff"),
  url("./fonts/DINNextLTPro-Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DIN Next LT Pro";
  src: url("./fonts/DINNextLTPro-Bold.eot?#iefix");
  src: url("./fonts/DINNextLTPro-Bold.eot?#iefix") format("eot"),
  url("./fonts/DINNextLTPro-Bold.woff") format("woff"),
  url("./fonts/DINNextLTPro-Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: bold;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 0;
}

body {
  margin: 0;
  font-family: -apple-system, 'DIN Next LT Pro', sans-serif, 'BlinkMacSystemFont',  'Segoe UI', 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: 'DIN Next LT Pro', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2, h1, h3, h4, h5, h6, .sf-menu, .main-menu, .hometext {
  font-family: 'DIN Next LT Pro';
}
@media all and (min-width: 769px){
  .visible-xs{
    display: none;
  }
  .hidden-xs{
    display: block;
  }
}
@media all and (max-width: 768px){
  .visible-xs{
    display: block;
  }
  .hidden-xs{
    display: none;
  }
}
