@import url(../../../index.css);

.buttonTransparent {
  margin: 0 10px 0 0;
  width: 100px;
  height: 40px;
  object-fit: contain;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.voucher-button {
  width: 100%;
  height: 56px;
  object-fit: contain;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid hsla(0, 0%, 100%, 0.4);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  margin: 2rem auto;
  font-family: "DINNextLTPro-Regular", sans-serif;
}

.buttonDetails {
  margin: 0 10px 0 0;
  object-fit: contain;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  font-size: 14px;
  padding: 1rem 3rem;
  cursor: pointer;
  font-family: "DINNextLTPro-Regular", sans-serif;
}
.buttonDetails:hover {
  filter: invert(1);
  background-color: black;
  transition: all 0.6s ease;
}

.buttonDetailsMobile {
  display: none;

  &--yt {
    display: none;
  }
}
.buttonDetails:hover {
  filter: invert(1);
  background-color: black;
  transition: all 0.6s ease;
}

.buttonPinkLink {
  width: 100px;
  height: 40px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #e53e63;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  font-size: 14px;
  border: none;
  cursor: pointer;
  display: block;
  padding-top: 12px;
  font-family: "DINNextLTPro-Regular", sans-serif;
}

.disabledButton {
  margin-top: 25px;
  width: 180px;
  height: 50px;
  background-color: #4b4546;
  border: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.buttonPink {
  width: 100%;
  height: 56px;
  object-fit: contain;
  border-radius: 2px;
  background-color: #e53e63;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  font-size: 14px;
  border: none;
  cursor: pointer;
  display: block;
  padding: 1.3rem 0;
  text-transform: uppercase;
  font-family: "DINNextLTPro-Regular", sans-serif;
}

.corpRequest {
  margin-top: 10px;
  font-family: "DIN Next LT Pro", sans-serif;
}

.checkoutButton {
  margin-top: 25px;
  width: 180px;
  height: 50px;
  background-color: #e53e63;
  border: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  font-family: "DINNextLTPro", sans-serif;
}

.buttonBook {
  object-fit: contain;
  border-radius: 10px;
  background-color: #e53e63;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  font-size: 14px;
  border: none;
  padding: 1rem 3rem;
  cursor: pointer;
  position: relative;
  z-index: 1;
  font-family: "DINNextLTPro", sans-serif;
}

.buttonCorporate {
  margin: 0;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 13px;
  cursor: pointer;
  padding: 16px 14%;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  font-family: "DINNextLTPro-Regular", sans-serif;
}

.textForButtonCorporate {
  position: absolute;
  color: #737373;
  text-align: center;
  font-size: 12px;
  top: 28px;
  left: 56px;
}

.buttonBook:hover,
.bookingRoom:hover {
  transition: all 0.6s ease;
  background-color: black;
}

.bookingRoom {
  width: 180px;
  height: 50px;
  // object-fit: contain;
  border-radius: 10px;
  background-color: #e53e63;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  display: inline-block;
  border: 1px solid transparent;
  color: #fff;
  font-size: 14px;
  padding-top: 19px;
  text-align: center;
  font-weight: 700;
  line-height: 1;
  vertical-align: top;
  position: relative;
  z-index: 1;
  cursor: pointer;
  text-decoration: none;
  font-family: "DINNextLTPro-Regular", sans-serif;
}

.playTrailerButton {
  padding-top: 0;
}

.buttonFaq {
  width: 180px;
  height: 50px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #e53e63;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  display: inline-block;
  border: 1px solid transparent;
  color: #fff;
  font-size: 14px;
  padding: 16px 30px 20px 30px;
  font-weight: 700;
  line-height: 1;
  vertical-align: top;
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-family: "DINNextLTPro-Regular", sans-serif;
  margin: 2em auto;
}

.buttonFaq:hover {
  transition: all 0.6s ease;
  background-color: black;
}

.buttonCase {
  position: relative;
  cursor: pointer;
  margin-right: 2rem;
  margin-bottom: 1rem;
  z-index: 2;
  font-family: "DINNextLTPro", sans-serif;

  .playTrailer {
    height: 54px;
    width: 54px;
    border-radius: 50%;
    border: 4px solid white;
    background-color: transparent;
    cursor: pointer;
    z-index: 1;

    &--triangle {
      background-image: url(../../../../public/img/logo/music-player-play.png);
      position: absolute;
      filter: invert(1);
      width: 16px;
      height: 16px;
      left: 21px;
      top: 20px;
      z-index: 0;
      background-size: contain;
      pointer-events: none;
    }

    &--text {
      position: absolute;
      color: white;
      top: 60px;
      left: -16px;
      width: 90px;
    }
  }
}

.buttonCase {
  margin-right: 0;
  margin-bottom: 0;

  .playTrailer {
    height: 54px;
    width: 54px;
    border-radius: 50%;
    border: 4px solid white;
    background-color: transparent;
    cursor: pointer;
    z-index: 1;

    &--triangle {
      background-image: url(../../../../public/img/logo/music-player-play.png);
      position: absolute;
      filter: invert(1);
      width: 16px;
      height: 16px;
      left: 21px;
      top: 20px;
      z-index: 0;
      background-size: contain;
      pointer-events: none;
    }

    &--text {
      position: absolute;
      color: white;
      top: 60px;
      left: -16px;
      width: 90px;
      font-family: "DINNextLTPro-Regular", sans-serif;
    }
  }
}

.buttonBook {
  border-radius: 2px;
  padding: 16px 36px 16px 36px;
  cursor: pointer;
}

.buttonCase:hover {
  .playTrailer {
    background-color: white;
    z-index: 1;
    transition: 0.6s;

    &--triangle {
      filter: none;
      z-index: 1;
      transition: 0.6s;
    }
  }
}

@media screen and (max-width: 414px) {
  .close-button {
    position: absolute;
    top: 12px;
    right: 16px;
    background: white;
    color: black;
    font-size: 36px;
    line-height: 12px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background 0.2s ease;
    padding: 12px 10px;
    text-decoration: none;
    height: 40px;
    width: 40px;

    &:hover {
      filter: invert(1);
    }
  }
}
@media screen and (min-width: 801px) {
  .close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    background: white;
    color: black;
    font-size: 36px;
    line-height: 12px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background 0.2s ease;
    padding: 12px 10px;
    text-decoration: none;
    height: 40px;
    width: 40px;

    &:hover {
      filter: invert(1);
    }
  }
}

@media screen and (max-width: 800px) and (min-width: 415px) {
  .close-button {
    position: absolute;
    top: 12px;
    right: 30px;
    background: white;
    color: black;
    font-size: 36px;
    line-height: 12px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background 0.2s ease;
    padding: 12px 10px;
    text-decoration: none;
    height: 40px;
    width: 40px;

    &:hover {
      filter: invert(1);
    }
  }
}
@media screen and (max-width: 800px) {
  .playTrailer {
    display: none;
    font-family: "DINNextLTPro-Regular", sans-serif;
    &--triangle {
      display: none;
    }

    &--text {
      display: none;
    }
  }
  .buttonTransparent {
    display: none;
  }

  .buttonPinkLink {
    border-radius: 2px;
  }
  .buttonDetailsMobile {
    display: block;
    margin: 0 10px 0 0;
    object-fit: contain;
    border-radius: 2px;
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    font-size: 14px;
    padding: 16px 20px 16px 40px;
    cursor: pointer;
    text-transform: uppercase;

    &--yt {
      display: block;
      background-image: url(../../../../public/img/logo/light.png);
      position: absolute;
      width: 24px;
      height: 22px;
      top: 0.8rem;
      left: 0.6rem;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .buttonDetails {
    display: none;
  }
}
