
.Toastify__toast-container--top-right {
  left: 0;
  right: 0;
  margin: auto;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
