.navMenuWrapper {
  min-height: 4rem;
  box-sizing: border-box;
}

.navMenu {
  .navMenuBlock {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    height: 4rem;
    gap:8rem;
    background-color: transparent;


    &--buttons {
      display: flex;
      gap: 0.4rem;
      justify-content: space-between;
    }

    img {
      cursor: pointer;
    }

    .navbar-toggle,
    .navbar-header {
      display: none;
    }

    &--link {
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 2rem;
      font-size: 14px;

      &--item {
        color: rgba(255, 255, 255, 0.6);
        text-decoration: none;
        cursor: pointer;
      }

      &--item:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 6px;
        cursor: pointer;
      }
    }
  }
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #765da9;
  z-index: 11;

  .navMenuBlock {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    height: 4rem;
    gap: 8rem;
    background-color: #765da9;
    padding: 0 5%;

    &--buttons {
      display: flex;
      gap: 0.4rem;
      justify-content: space-between;
    }

    .navbar-toggle,
    .navbar-header {
      display: none;
    }

    img {
      cursor: pointer;
    }

    &--link {
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 2rem;
      font-size: 14px;
      cursor: pointer;

      &--item {
        color: rgba(255, 255, 255, 0.6);
        text-decoration: none;
      }

      &--item:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 6px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  // .mobileNone{display: none;}

  .navMenu {
    .navMenuBlock {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      align-items: center;
      height: 4rem;
      gap: 1rem;
      background-color: transparent;

      img {
        cursor: pointer;
      }

      &--link {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 10px;
        font-size: 14px;

        &--item {
          color: rgba(255, 255, 255, 0.6);
          text-decoration: none;
          cursor: pointer;
        }

        &--item:hover {
          text-decoration: underline;
          text-decoration-thickness: 2px;
          text-underline-offset: 6px;
          cursor: pointer;
        }
      }
    }
  }

  .sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #765da9;
    z-index: 11;
    

    .navMenuBlock {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      align-items: center;
      height: 4rem;
      gap: 1rem;
      background-color: #765da9;

      img {
        cursor: pointer;
      }

      &--link {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 10px;
        font-size: 14px;
        cursor: pointer;

        &--item {
          color: rgba(255, 255, 255, 0.6);
          text-decoration: none;
        }

        &--item:hover {
          text-decoration: underline;
          text-decoration-thickness: 2px;
          text-underline-offset: 6px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {

  .navMenu {

    .navMenuBlock {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: center;
      height: 4rem;
      gap: 0;
      width: 100%;
      background-color: transparent;
      border-bottom: 1px solid white;
      margin: 0 auto;
      &--buttons {
        display: none
      }
     

      .minButtonsLeftPart {
        display: flex;
        gap: 4%;
        width: 160px;
        justify-content: flex-end;
      }

      img {
        cursor: pointer;
        z-index: 12;
      }

      .navbar-header {
        width: 40px;
        height: 40px;
        background-color: rgb(255, 255, 255);
        display: block;
        position: relative;
      }
      .navbar-toggle {
        background-image: url("../../../public/img/logo/dark.png");
        height: 16px;
        width: 16px;
        position: absolute;
        display: block;
        border: none;
        left: 0.75rem;
        top: 0.75rem;
        z-index: 2;
        background-color: white;
        background-position: center;
        background-size: contain;
      }

      &--link {
        display: none;
      }
    }
  }

  .sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: #765da9;
    z-index: 16;

    .minButtonsLeftPart {
      display: flex;
      gap: 4%;
      width: 160px;
      justify-content: flex-end;
    }

    .navMenuBlock {
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: center;
      height: 4rem;
      gap: 0;
      background-color: #765da9;
      padding: 0 5%;
      &--buttons {
        display: none
      }

      img {
        cursor: pointer;
        z-index: 12;
      }

      .navbar-header {
        width: 40px;
        height: 40px;
        background-color: rgb(255, 255, 255);
        display: block;
        position: relative;
        cursor: pointer;
      }
      .navbar-toggle {
        background-image: url("../../../public/img/logo/dark.png");
        height: 16px;
        width: 16px;
        position: absolute;
        display: block;
        border: none;
        left: 0.75rem;
        top: 0.75rem;
        z-index: 2;
        background-color: white;
        background-position: center;
        background-size: contain;
      }

      &--link {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 414px) {

  .sticky {
    .navMenuBlock {
      padding: 0 5%;
    }
  }
}
