// .new-prices {
//   display: none;
// }
.pricesBlock {
  display: none;
}
// .pricesBlock {
//   background-color: #26272d;
//   color: white;
//   padding: 3% 0;

//   &--head {
//     font-size: 28px;
//     font-family: "DINNextLTPro-Regular", Arial, serif;
//     padding-bottom: 2rem;
//   }

//   &--info {
//     margin: 4rem auto;

//     p {
//       font-size: 17px;
//       color: white;
//       font-family: "DINNextLTPro-Regular";
//       margin-top: 30px;
//     }
//   }

//   &__group {
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     gap: 4rem;

//     &--part {
//       h3 {
//         border-bottom: 1px solid #3f4458;
//         padding-bottom: 1rem;
//       }

//       .groups {
//         display: flex;
//         flex-direction: column;

//         &--mini {
//           display: flex;
//           flex-direction: row;
//           border-bottom: 1px solid #3f4458;
//           gap: 4rem;
//           text-align: left;
//           justify-content: flex-start;
//           margin-top: 1rem;
//           padding-bottom: 1rem;

//           .members {
//             width: 50%;
//             margin-right: 3rem;

//             &--time {
//               border-radius: 50px;
//               padding: 0.5rem 2rem;
//               font-family: DINNextLTPro-Regular, sans-serif;
//               font-size: 16px;
//               border: 1px solid #e9c86d;
//               color: #e9c86d;
//               background: #393e54;
//               font-weight: 700;
//               position: relative;
//               margin-left: 25px;
//               margin-bottom: 20px;
//             }

//             &--time:before {
//               content: "\221f";
//               display: inline-block;
//               width: 20px;
//               height: 24px;
//               border-radius: 50%;
//               color: #fff;
//               background: #e9c86d;
//               font-size: 12px;
//               font-weight: 700;
//               top: 4px;
//               left: -14px;
//               position: absolute;
//               padding-top: 6px;
//               padding-left: 10px;
//             }

//             &--players {
//               color: #3f4458;
//               font-size: 14px;
//               margin: 7px 0 0;
//             }

//             img {
//               padding-top: 2rem;
//               margin: 0 2px;
//             }
//           }
//         }

//         &--mini2 {
//           border-bottom: 1px solid #3f4458;
//           text-align: left;
//           margin-top: 1rem;
//           padding-bottom: 1rem;

//           .members2 {
//             margin-right: 3rem;

//             &--time {
//               border: 1px solid #e9c86d;
//               color: #e9c86d;
//               background: #393e54;
//               font-weight: 700;
//               border-radius: 50px;
//               padding: 0.5rem 2rem;
//               font-family: "DINNextLTPro-Regular", sans-serif;
//               font-size: 16px;
//               position: relative;
//               margin-left: 25px;
//               margin-bottom: 20px;
//             }

//             &--time:before {
//               content: "\221f";
//               display: inline-block;
//               width: 20px;
//               height: 24px;
//               border-radius: 50%;
//               color: #fff;
//               background: #e9c86d;
//               font-size: 12px;
//               font-weight: 700;
//               top: 4px;
//               left: -14px;
//               position: absolute;
//               padding-top: 6px;
//               padding-left: 10px;
//             }

//             &--players {
//               color: #3f4458;
//               font-size: 14px;
//               margin: 0.5rem 0;
//             }

//             img {
//               padding-top: 2rem;
//               margin: 0 2px;
//             }
//           }
//         }
//       }
//     }
//   }
// }

.ed-prices-block {
  color: white;

  margin-top: 10px;
  display: flex;
  align-items: baseline;

  &-cost {
    font-size: 32px;
    padding-right: 8px;
  }
  &-text {
    font-size: 17px;
  }
}

// @media screen and (max-width: 820px) {
.pricesBlock {
  display: none;

  &--head {
    text-transform: uppercase;
    padding: 1rem 0;
    margin: 0 auto;

  }
}
.new-prices {
  display: block;
  padding: 0 5%;
  margin:0 auto;
}

.new-prices {
  background-color: #191919;
  color: #ffffff;
  padding-bottom: 5rem;
  // margin-top: 3rem;
}

.prices_head {
  vertical-align: middle;
  // width: 92vw;
}

.price_description {
  border: 1px solid rgba(255, 255, 255, 0.404);
  margin-top: 2rem;
  border-radius: 2px;
  text-align: left;
  max-width: 360px;
}

.Fire {
  background-image: url("../../../public/img/logo/FireBig.png");
  position: absolute;
  width: 16.5px;
  height: 20px;
  margin-top: 1rem;
  margin-left: 1rem;
}

.price_description-head {
  opacity: 0.4;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  margin:17px 17px 17px 55px;
}

.price_description-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 17px 17px 17px 55px
}

.pricelist-1,
.pricelist-2,
.pricelist-3 {
  margin-top: 4rem;
}

.number-prices {
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: white;
}

.name-head {
  display: inline-block;
  font-weight: 600;
  font-size: 60px;
  line-height: 60px;
  text-transform: uppercase;
  color: white;
  position: relative;
  margin-top: 4%;
}

.priceRound {
  background-image: url("../../../public/img/logo/Oval.png");
  background-repeat: no-repeat;
  position: absolute;
  z-index: 2;
  height: 18px;
  width: 9px;
  background-position-x: -8px;
  overflow: hidden;
  border-radius: 0 100% 100% 0 / 0 50% 50% 0;
  top: 44%;
  left: 0;
}

.priceRound2 {
  background-image: url("../../../public/img/logo/Oval.png");
  background-repeat: no-repeat;
  position: absolute;
  z-index: 2;
  height: 16px;
  width: 9px;
  background-position-x: -8px;
  overflow: hidden;
  border-radius: 0 100% 100% 0 / 0 50% 50% 0;
  top: 44%;
  left: 0;
}

.priceRound2-pink {
  background-image: url("../../../public/img/logo/Oval.png");
  background-repeat: no-repeat;
  position: absolute;
  z-index: 2;
  height: 16px;
  width: 9px;
  background-position-x: -8px;
  overflow: hidden;
  border-radius: 0 100% 100% 0 / 0 50% 50% 0;
  top: 44%;
  left: -1px;
  border-top: 1px solid #e53e63;
  border-bottom: 1px solid #e53e63;
  border-right: 1px solid #e53e63;
}

.priceRound-lilac {
  background-image: url("../../../public/img/logo/Oval.png");
  background-repeat: no-repeat;
  position: absolute;
  z-index: 2;
  height: 16px;
  width: 9px;
  background-position-x: -8px;
  overflow: hidden;
  border-radius: 0 100% 100% 0 / 0 50% 50% 0;
  top: 44%;
  left: -1px;
  border-top: 1px solid #765da9;
  border-bottom: 1px solid #765da9;
  border-right: 1px solid #765da9;
}

.priceWeek {
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  color: #ffffff;
  width: 200px;
  text-align: left;
  font-family: "DINNextLTPro-Regular", sans-serif;
}

.price-up {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.timeGroup {
  position: relative;
}

.priceTime {
  color: #000000;
  background-color: white;
  /*height: 3rem;*/
  border-radius: 30px;
  padding: 5px 11px 5px 25px;
  line-height: 14px;
  font-size: 13px;
}

.priceClock {
  background-image: url("../../../public/img/logo/clock.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 2;
  height: 14px;
  width: 14px;
  top: 4px;
  left: 6px;
}

.priceTicket {
  display: flex;
  justify-content: space-between;
}

.priceTicket2 {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.priceTicket-left-first {
  background-color: #765da9;
  width: 78%;
  height: 6rem;
  border-radius: 2px;
  position: relative;
  padding-left: 5%;
  text-align: left;
  padding-top: 1rem;
}
.priceTicket-left {
  background-color: #765da9;
  width: 78%;
  height: 6rem;
  border-radius: 2px;
  position: relative;
  padding-left: 5%;
  text-align: left;
}

.priceTicket-left-pink {
  background-color: #e53e63;
  width: 78%;
  height: 6rem;
  border-radius: 2px;
  position: relative;
  padding-left: 5%;
  text-align: left;
  padding-top: 0;
}

.priceTicket-left-transparent {
  border: 1px solid #765da9;
  width: 78%;
  height: 6rem;
  border-radius: 2px;
  padding-top: 1rem;
  position: relative;
  padding-left: 5%;
  text-align: left;

}

.priceTicket-left-pink-transparent {
  border: 1px solid #e53e63;
  width: 78%;
  height: 6rem;
  border-radius: 2px;
  position: relative;
  padding-left: 5%;
  text-align: left;
  padding-top: 1rem;
}

.priceSale {
  text-align: left;
  background-color: #000000;
  width: 4rem;
  height: 1rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  border-radius: 20px;
  padding-left: 1.4rem;
  padding-top: 1px;
  position: relative;
  margin-top: 0.3rem;
  margin-bottom: 0;
  text-transform: uppercase;
}

.priceFire {
  background-image: url("../../../public/img/logo/Fire.png");
  position: absolute;
  width: 11px;
  height: 12px;
  margin-top: -1rem;
  margin-left: 0.4rem;
  background-repeat: no-repeat;
  background-size: contain;
}

.priceNew {
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  display: inline-block;
  width: 50px;
  margin-right: 8%;
}

.priceOld {
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  display: inline-block;
  width: 50px;
  margin-bottom: 0;
  text-decoration-thickness: 3px;
  text-decoration-line: line-through;
  text-decoration-color: #000;
}

.pricePerson {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  mix-blend-mode: normal;
  opacity: 0.4;
  display: inline-block;
  margin-bottom: 0;
}

.person-opacity {
  opacity: 0.4;
}

.priceImg img {
  height: 14px;
  width: 14px;
  object-fit: contain;
}

.priceTicket-right {
  width: 20%;
  border: 1px solid #ffffff42;
  border-radius: 2px;
  height: 6rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  text-align: center;
}

.priceTicket-right_bold {
  font-weight: 600;
  font-size: 32px;
  padding-top: 22px;
  line-height: 32px;
}

.paragraphOfPlayers {
  margin-top: 5rem;
}

.pricelist{
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;

  hr{
    width: 100%;
    border: none;
  }
}


// }

@media screen and (max-width: 414px) {
  .pricesBlock--head {
    display: none;}

  .priceTime {
    color: #000000;
    background-color: white;
    /*height: 2rem;*/
    border-radius: 30px;
    padding: 5px 10px 5px 30px;
    line-height: 14px;
    font-size: 14px;
  }

  .priceNew {
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    text-transform: uppercase;
    display: inline-block;
    width: 50px;
  }

  .name-head {
    font-size: 24px;
    line-height: 20px;
  }
}
