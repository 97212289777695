.reviews {
  display: none;
}
.reviewsOutdoor, .reviewsCorporate {
  color: white;
  display: block;
  background-color: #e53e63;
  padding: 40px 5% 0;
  padding-bottom: 4rem;

  &__description {
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 12px;
    max-width: 500px;
    margin: 0 auto;

    &--head {
      opacity: 1;
      text-align: left;
      font-size: 24px;
      margin-bottom: 12px;
    }

    p {
      text-align: left;
      font-size: 16px;
      line-height: 1.41em;
    }

    &__bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;

      &--bord {
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        width: 140px;
        height: 1px;
      }
    }
  }
  .custom-dots {
    display: flex !important;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    content: url("../../../public/img/logo/dotact2.png");
  }
  .slick-dots {
    position: absolute;
    bottom: -76px;
    background-repeat: no-repeat;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    color: transparent;
  }

  .slick-dots li button:before {
    content: url("../../../public/img/logo/dotina2.png");
    opacity: 1;
    color: transparent;
  }

  .slick-dots li {
    margin: 0 2px;
  }

  .slick-active {
    margin: 0 0;
  }

  .custom-dots li {
    margin: 0;
    padding: 0;
  }

  .custom-dots li button {
    cursor: pointer;
  }
}

@media screen and (max-width: 900px) {
  .reviewsOutdoor, .reviewsCorporate {
    &__description {
      width: 100%;
    }
  }
}

@media screen and (max-width: 414px) {

  .reviewsOutdoor, .reviewsCorporate {
    padding: 0 5% 40px;
  }
  .custom-dots {
    display: flex !important;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    content: url("../../../public/img/logo/dotact2.png");
  }
  .slick-dots {
    position: absolute;
    bottom: -76px;
    background-repeat: no-repeat;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    color: transparent;
  }

  .slick-dots li button:before {
    content: url("../../../public/img/logo/dotina2.png");
    opacity: 1;
    color: transparent;
  }

  .slick-dots li {
    margin: 0 2px;
  }

  .slick-active {
    margin: 0 0;
  }

  .custom-dots li {
    margin: 0;
    padding: 0;
  }

  .custom-dots li button {
    cursor: pointer;
  }

  .reviews {
    color: white;
    display: block;
    background-color: #e53e63;
    padding: 0 5%;
    padding-bottom: 4rem;

    &__description {
      border: 1px solid rgba(255, 255, 255, 0.4);
      padding: 12px;

      &--head {
        opacity: 0.4;
        text-align: left;
        font-size: 18px;
        margin-bottom: 12px;
      }

      p {
        text-align: left;
        font-size: 14px;
      }

      &__bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;

        &--bord {
          border-bottom: 1px solid rgba(255, 255, 255, 0.4);
          width: 140px;
          height: 1px;
        }
      }
    }
  }

  .starOpacity {
    opacity: 0.4;
  }
}
