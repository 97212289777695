.headerrezervari {
  display: flex;
  background-color: white;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.ziorar {
  padding: 5px 4px 5px 4px;
  width: calc(100% / 8);
  text-align: center;
  font-size: 12px;
  color: #6a717a;
}

.ziorardata {
  font-size: 14px;
  color: #25272a;
  font-weight: 700;
}

.bodyrezervari {
  display: inline-flex;
  background-color: #fff;
  width: 100%;
}

.datacalendar {
  padding: 15px 0 15px 0;
  text-align: center;
  font-size: 20px;
  color: #fff;
  background-color: #80d7c4;
  width: calc(100% / 7);
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.datacalendar.active {
  padding: 15px 0 15px 0;
  text-align: center;
  font-size: 20px;
  color: #fff;
  background-color: #e53e63;
  width: calc(100% / 7);
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.datacalendar:last-child {
  border-right: 0 solid #fff;
}
a.linkrezervari {
  transition: all 0.5s ease;
  color: #fff;
  text-decoration: none;
}
a.linkrezervari:hover {
  color: #000;
}

.sageatafooter {
  float: right;
  cursor: pointer;
}

.sageatafooternotblur {
  float: right;
  cursor: pointer;
  z-index: 1;
  position: relative;
  margin-top: -33px;
  margin-right: 9px !important;
}

.sageatafooterback {
  float: left;
  cursor: pointer;
  width: 25px;
}

.sageataback {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.textfooterrezervari {
  padding: 1rem 24px 1rem 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #25272a;
}

.bookingContainerOfEachCard {
  min-width: 490px;
  min-height: auto;
  position: relative;
}

.bookingContainer {
  background: linear-gradient(135deg, rgb(56, 29, 110), rgb(211, 126, 121));
  padding-bottom: 2rem;
}

.bookingContainer > h2 {
  padding: 2rem 0 2rem;
  font-size: 30px;
  line-height: 30px;
  color: white;
}

.bookingCard {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem 2rem;
  padding: 0 16%;
  margin: 0 auto;
}

.leftHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 700;
}

.headerOfTable {
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;
  margin: 2rem 0;
}

.tableBooking {
  width: 30vw;
  background-color: white;
  border-radius: 10px;
  min-width: 490px;
  max-width: 720px;
}

.accessibilityTables img,
.difficultyTable img {
  width: 20px;
  height: 20px;
  margin-left: 6px;
}

.accessibilityTables,
.difficultyTable {
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  justify-content: flex-start;
  font-size: 14px;
  margin-bottom: 3px;
}

.outDoorContainer {
  background: linear-gradient(135deg, rgb(56, 29, 110), rgb(211, 126, 121));
}

.bookingOutdoor {
  margin: 0 auto;
  position: relative;
  width: 100%;
  padding: 0 0 20px;

  &__headerOfTable {
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 0 1rem;
    gap: 1rem;
    margin: 0 auto;
    width: 490px;

    .leftHeader img {
      width: 80px;
      height: 80px;
      top:0;
    }
  }

  &__tableBooking {
    width: 30vw;
    background-color: white;
    border-radius: 10px;
    min-width: 490px;
    max-width: 720px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 540px) {
  .bookingOutdoor {
    margin: 0 auto;
    position: relative;

    &__headerOfTable {
      display: flex;
      flex-direction: row;
      color: white;
      align-items: center;
      justify-content: space-between;
      padding: 3rem 0 1rem;
      gap: 1rem;
      margin: 0 auto;
    
    }

    &__tableBooking {
      background-color: white;
      border-radius: 10px;
      min-width: 300px;
      max-width: 90vw;
      margin: 0 auto;
      width: 90vw;
    }
  }

  .bookingOutdoor {
    margin: 0 auto;
    position: relative;
    width: 90vw;

    &__headerOfTable {
      display: flex;
      flex-direction: column;
      color: white;
      align-items: flex-start;
      justify-content: center;
      margin: 0 0 1rem 28%;
      padding: 3rem 0 1rem;
      gap: 1rem;
      width: 60vw;
    }

  }
  .tableBooking {
    width: 90vw;
    min-width: 90vw;
    max-width: none;
  }

  .bookingCard {
    padding: 0 auto;
    margin: 0 auto;
  }

  .datacalendar {
    padding: 4px auto;
    font-size: 14px;
  }
  .datacalendar.active {
    padding: 4px auto;
    text-align: center;
    font-size: 14px;
  }

  .headerOfTable {
    display: flex;
    flex-direction: column;
    color: white;
    align-items: flex-start;
    margin: 3rem 0 1rem;
    gap: 1rem;
    margin-left: 28%;
  }

  .leftHeader {
    position: relative;
  }

  .leftHeader img {
    position: absolute;
    left: -100px;
    top: -28px;
  }

  .bookingContainerOfEachCard {
    min-width: 90vw;
  }
}

@media screen and (max-width: 414px) {
  .bookingOutdoor {
    margin: 0 auto;
    position: relative;
    width: 90vw;
   

    &__headerOfTable {
      width: 60vw;
/*      width: 90vw;*/
    }

    &__tableBooking {
      max-width: 100%;
    }
  }

  .bookingContainer {
    padding: 0 5% 5%;
  }
  .bookingCard {
    padding: 0;
  }

  .bookingContainer > h2 {
    display: none;
  }
}
