.loaderGroup {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;
}

.loaderMiniVisible {
  position: absolute;
  z-index: 10;
  top: 20%;
  left: 0;
  object-position: 50%;
  backdrop-filter: blur(5px);
  width: 100%;
  height: 78%;

  .loaderImg {
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}


.image-container {
    position: relative;
    animation: lds-dual-ring 2s linear infinite;
  }

.loaderFullScreen {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(5px);
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

 

  .loaderImg {
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  
}


@media screen and (max-width: 414px) {
  .loaderMiniVisible {
    position: absolute;
    z-index: 10;
    top: 23%;
    left: 0;
    object-position: 50%;
    backdrop-filter: blur(5px);
    width: 100%;
    height: 75%;

    .loaderImg {
        width: 80px;
        height: 80px;
        top: 50%;
        left: 50%;
        margin: 0;
        transform: translate(-50%, -50%);
        position: absolute;
      }
  }
}
.loaderMiniInvisible {
  display: none;
  z-index: -5;
}

.loaderImg {
  width: 80px;
  height: 80px;
//   margin-top: 1.8rem;
  filter: invert(1);
//   animation: lds-dual-ring 2s linear infinite;
}
