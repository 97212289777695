.gallery {
  background-color: #191919;
}

.photoBox {
  width: 100%;
  height: 0;
  padding-bottom: 33%;
  position: relative;
  overflow: hidden;
  background-color: #26272d;

  &--images {
    cursor: pointer;
    background-color: black;

    &--1,
    &--2,
    &--3,
    &--4,
    &--5,
    &--6,
    &--7,
    &--8 {
      position: absolute;
      width: 25vw;
      object-fit: cover;

      img {
        position: absolute;
        width: 100%;
        object-fit: cover;
        left: 0;
      }

      &.darken img {
        filter: brightness(50%);
        transition: 0.3s;
      }
    }

    &--1 {
      left: 0;
    }

    &--2 {
      left: 25%;
    }

    &--3 {
      left: 50%;
    }

    &--4 {
      left: 75%;
    }
    &--5 {
      left: 0;
      top: 50%;
    }
    &--6 {
      left: 25%;
      top: 50%;
    }
    &--7 {
      left: 50%;
      top: 50%;
    }
    &--8 {
      left: 75%;
      top: 50%;
    }
  }

  &--modal {
    position: relative;

    img {
      width: 58vw;
      height: 70vh;
      object-fit: cover;
    }

    button {
      background: transparent;
      border: none;
      color: #fff;
      font-size: 3em;
      cursor: pointer;
      text-shadow: black 1px 0 10px;

      &:hover {
        color: rgb(219, 219, 219);
      }

      &.left {
        position: absolute;
        left: -6rem;
      }

      &.right {
        position: absolute;
        right: -6rem;
      }

      &.close {
        position: absolute;
        top: 0;
        right: -6rem;
      }
    }

    transition: opacity 0.5s ease-in-out;
    opacity: 0;
  }

  &--modal.is-open {
    opacity: 1;
    position: relative;

    z-index: 5;
    width: 56vw;
    height: 70vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 16%;
  }
}
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  z-index: 11;
}

@media screen and (max-width: 800px) {
  .gallery {
    padding: 0 5%;
  }
  .photoBox {
    width: 110%;
    height: 0;
    padding-bottom: 133%;
    position: relative;
    overflow: hidden;
    background-color: #26272d;
    left: -5%;

    &--images {
      cursor: pointer;
      background-color: black;

      &--1,
      &--2,
      &--3,
      &--4,
      &--5,
      &--6,
      &--7,
      &--8 {
        position: absolute;
        width: 50vw;
        object-fit: cover;

        img {
          position: absolute;
          width: 100%;
          object-fit: cover;
          left: 0;
        }

        &.darken img {
          filter: brightness(50%);
          transition: 0.3s;
        }
      }

      &--1 {
        left: 0;
      }

      &--2 {
        left: 50%;
      }

      &--3 {
        left: 0;
        top: 25%;
      }

      &--4 {
        left: 50%;
        top: 25%;
      }
      &--5 {
        left: 0;
        top: 50%;
      }
      &--6 {
        left: 50%;
        top: 50%;
      }
      &--7 {
        left: 0%;
        top: 75%;
      }
      &--8 {
        left: 50%;
        top: 75%;
      }
    }

    &--modal {
      position: relative;

      img {
        width: 100%;
        height: 70vh;
        object-fit: contain;
      }

      button {
        background: transparent;
        border: none;
        color: #fff;
        font-size: 3em;
        cursor: pointer;
        text-shadow: black 1px 0 10px;

        &:hover {
          color: rgb(219, 219, 219);
        }

        &.left {
          position: absolute;
          left: -6rem;
        }

        &.right {
          position: absolute;
          right: -6rem;
        }

        &.close {
          position: absolute;
          top: 0;
          right: 2rem;
        }
      }

      transition: opacity 0.5s ease-in-out;
      opacity: 0;
    }

    &--modal.is-open {
      opacity: 1;
      position: relative;

      z-index: 5;
      width: 100%;
      height: 70vh;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 16%;
    }
  }
}

