// .faq {
//   position: relative;
//   display: flex;
//   justify-content: flex-end;
//   height: 100%;
// }

// .faqLeft {
//   width: 60%;
//   color: white;
//   position: relative;

//   &--img {
//     background-image: url(../../../public/img/pictures/faqbg.png);
//     background-repeat: repeat;
//     background-origin: padding-box;
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     margin: 0 0;
//     z-index: -1;
//   }

//   &--text {
//     text-align: left;
//     margin: 10rem auto 0;
//     width: 24rem;
//   }
// }

// .questions {
//   width: 40%;
//   margin: 4% auto;
// }

// .questionsGroup {
//   width: 56%;
//   margin-right: auto;
//   margin-left: 0;
// }

// .list {
//   border: 1px solid #e9c86d;
//   border-radius: 12px;
//   list-style-type: none;
//   margin-bottom: 16px;

//   &__Button {
//     width: 100%;
//     border: none;
//     background-color: transparent;
//     padding: 1rem 0;
//     cursor: pointer;
//     transition: 2s;

//     span {
//       color: #e9c86d;
//     }
//   }
// }

// .answerContent {
//   padding: 0 1rem 2rem;
//   word-wrap: break-word;
//   text-align: left;
//   max-height: 0;
//   overflow: hidden;
//   transition: max-height 0.2s ease-out;
//   display: none;

//   span {
//     color: #e9c86d;
//   }
// }

// .answerContent.open {
//   max-height: 10rem;
//   display: block;
// }

// .answerContent.closed {
//   max-height: 0;
// }

.faq {
  position: relative;
  height: 100%;
  margin: 0 auto 4%;
  padding: 0 auto;
}

.faqLeft {
  color: white;
  position: relative;

  &--text {
    text-align: center;
    margin: 4rem auto 0;
    width: 24rem;
    color: black;

    h2 {
      font-weight: 600;
      font-size: 60px;
      color: #e53e63;
    }

    p {
      width: auto;
    }
  }
}

.questions {
  width: 40%;
  margin: 0 auto;
}

.questionsGroup {
  margin: 0 auto;
}

.list {
  border: 1px solid #e53e63;
  border-radius: 2px;
  list-style-type: none;
  margin-bottom: 16px;

  &:active,
  &:focus,
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.4);
  }

  &__Button {
    width: 100%;
    border: none;
    background-color: transparent;
    padding: 1rem 1.6rem;
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
    // font-family: "DIN Next LT Pro", sans-serif;
    font-weight: 400;
    position: relative;
    color: #000;

    &:active,
    &:focus {
      color: #e53e63;
    }

    span {
      position: absolute;
      right: 1rem;
      top: 45%;
      font-size: 20px;
      line-height: 5px;
      opacity: 0.4;
      color: black;
    }

    .greyOval {
      height: 22px;
      width: 22px;
      position: absolute;
      top: 8px;
      left: -12px;
    }

    .questionPoint {
      &--sign {
        z-index: 2;
        position: absolute;
        top: 11px;
        left: -9px;
      }

      &--oval {
        height: 22px;
        width: 22px;
        position: absolute;
        top: 8px;
        left: -12px;
      }
    }
  }
}

.answerContent {
  margin: 1rem 1rem 2rem;
  word-wrap: break-word;
  text-align: left;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  display: none;
}

.answerContent.open {
  max-height: 10rem;
  display: block;
  font-size: 14px;
  line-height: 17px;
  color: #737373;
}

.answerContent.closed {
  max-height: 0;
}


@media screen and (max-width: 820px) {
  .faqLeft {
    color: white;
    position: relative;

    &--text {
      text-align: center;
      margin: 0 auto 0;
      width: 90%;
      color: black;

      h2 {
        font-weight: 600;
        font-size: 60px;
        color: #e53e63;
      }

      p {
        width: auto;
      }
    }
  }
  .questions {
    width:90%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 414px) {
.faq {
  margin: 0 5%;
}

.questions {
  width:100%;
  margin: 0 auto;
}
  .faqLeft {
    &--text {
      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 120px;
        line-height: 144px;
      }

      p {
        color: #737373;
      }
    }
  }
}
