// @media screen and (max-width: 800px) {
.dropdown {
  position: relative;
  &__mobileMenu {
    background-color: #765da9;
    color: white;
    height: 100vh;
    text-transform: uppercase;
    text-align: left;
    padding: 6rem 1rem 0 1rem;
    font-weight: 900;
    font-size: 32px;

    &--img {
      position: absolute;
      top: 14px;
      left: 30px;
    }

    &--li {
      display: block;
      padding-bottom: 8px;
      padding-left: 2rem;
      cursor: pointer;
    }

    &--li:hover{
      color: #000000;
    }

    .smallLi {
      color: white;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 414px) {
  .dropdown {
    &__mobileMenu {
      &--img {
        top: 14px;
        left: 16px;
      }
    }
  }
}
